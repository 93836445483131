import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

class News extends Component {
    render(){
        return (
            <Row className="newsContainer" id="news">
                <h1>
                    NOVICE
                </h1>
                <div className="mainNews">
                    <div className="newsArticle1">
                        <h3>MDNS Maribor z 9 novimi člani!</h3>
                            <p>
                            MDNS Maribor je bogatejše za 9 novih mladih sodnikov (slika, čepijo). Po uspešno opravljenem tečaju je sledil praktični prikaz sojenja za nove sodnike, katerega so izvedli starejši sodniki pod nadzorom predsednika strokovnega odbora g. Borošaka (slika, stojijo). 
                            </p>
                            <a href="tecaj.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='tecaj.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle1">
                        <h3>Primer uporabe komunikacijskega sistema med tekmo</h3>
                            <p>
                            Jernej (slika, na sredini) in Elmedin (slika, levo) sta na tekmi 4. lige, s pomočjo komunikacijskega sistema AXIWI uspešno rešila kočljivo situacijo v kazenskem prostoru. Med prodorom napadalca po levem krilu, kar je nadzoroval Jernej kot glavni sodnik, je prišlo do prekrška med drugim napadalcem in branilcem ki sta se nahajala v kazenskem prostoru. 
                            Glavni sodnik tega ni mogel zaznati in pravilno ukrepati, zato mu je 1. pomočnik sodnika Elmedin pri tem pomagal in mu s pomočjo komunikacijskega sistema podal potrebno informacijo o storjenem prekršku napadalca nad branilcem. Glavni sodnik je nato pravilno dosodil prekršek za obrambo.
                            </p>
                            <a href="mnz.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='mnz.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle1">
                    <h3>Priporočila za uporabo komunikacijskega sistema</h3>
                            <p>
                            Tomaž Jezeršek, sodnik 3. slovenske nogometne lige, s priporočili o uporabi komunikacijskega sistema AXIWI (klik na spodnjo sliko)
                            </p>
                            <a href="axiwi-clanek.pdf" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='komunikacijaNavodila.png' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle2">
                    <h3>AXIWI na 2.SNL</h3>
                            <p>
                            Mariborski sodniki na tekmi 2. SNL z novim komunikacijskim sistemom AXIWI.
                            </p>
                            <a href="snl2.jpg" target="_blank">
                                <img className="newsImage rounded img-thumbnail mx-auto" src='snl2.jpg' loading="lazy"></img>
                                <div class="overlay"></div>
                            </a>
                    </div>
                    <div className="newsArticle3">
                        <h3>Komunikacijski sistemi AXIWI</h3>
                            <p>
                            MDNS Maribor je sklenilo sodelovanje z nizozemskim ponudnikom komunikacijskih sistemov za sodnike, AXIWI. Nove komunikacijski komunikacijski sistemi AXIWI AT-350 bodo sodnikom v pomoč pri deljenju pravice na slovenskih igriščih.
                            </p>
                        <a href="https://www.axiwi.com/ " target="_blank">
                            <img className="newsImage rounded" src='axiwiLogo.png' loading="lazy"></img>
                            <div class="overlay"></div>
                        </a>
                    </div>                    
                </div>
            </Row>
        )
    }
}

export default News;